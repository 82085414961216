import { Card, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';
import { useUpdateProfile } from '#/api/userQueries';
import useAuth from '#/hooks/useAuth';
import { useUpdateAdvisorName } from '#/api/partnerQueries';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function MainInfoModal({ open, onClose }: Props) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Card
        sx={{
          p: 2,
        }}
      >
        <Stack direction="column">
          <Typography variant="subtitle1">
            {String(translate('adminDashboard.mainInfoModal.title'))}
          </Typography>
          <Typography variant="body2">
            {String(translate('adminDashboard.mainInfoModal.description'))}
          </Typography>
        </Stack>
        <MainForm onClose={onClose} />
      </Card>
    </DialogAnimate>
  );
}

type FormProps = {
  onClose: () => void;
};

const MainForm = ({ onClose }: FormProps) => {
  const { translate } = useLocales();
  const { refetch } = useAuth();

  const { mutateAsync: updateProfile, isLoading } = useUpdateProfile();
  const { mutateAsync: updateAdvisorName } = useUpdateAdvisorName();

  const defaultValues = {
    first_name: '',
    last_name: '',
  };

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    try {
      await updateProfile(data).then(async () => {
        toast.success(
          String(translate('toast_notifications.success.profile_update'))
        );
        await updateAdvisorName(data);
        onClose();
        await refetch();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          my: 2,
        }}
      >
        <RHFTextField
          name="first_name"
          label={String(translate('global.formLabels.firstName'))}
        />
        <RHFTextField
          name="last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          <Typography>{String(translate('global.save'))}</Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
